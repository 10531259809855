@use './theme/colors'
@use './theme/theme'
@use '@angular/material' as mat

@include mat.core()

body
  margin: 0
  font-family: Roboto, "Helvetica Neue", sans-serif !important
  font-size: 14px
  font-weight: 400
  font-feature-settings: normal
  font-kerning: auto
  font-optical-sizing: auto
  font-palette: normal
  font-stretch: 100%
  font-style: normal
  font-synthesis-small-caps: auto
  font-synthesis-style: auto
  font-synthesis-weight: auto
  font-variant-alternates: normal
  font-variant-caps: normal
  font-variant-east-asian: normal
  font-variant-ligatures: normal
  font-variant-numeric: normal
  font-variation-settings: normal

$primary-palette: mat.m2-define-palette(colors.$primary, 600, 200, 800)
$accent-palette: mat.m2-define-palette(colors.$accent, 700, 300, 900)
$warn-palette: mat.m2-define-palette(colors.$warn)

$app-theme: mat.m2-define-light-theme(theme.$app-theme)

@include mat.all-component-themes($app-theme)
@include mat.elevation-classes()

// Fix https://github.com/Leaflet/Leaflet/issues/3575
img.leaflet-tile, img.leaflet-marker-icon, img.leaflet-marker-shadow
  // work-around from here: https://github.com/Leaflet/Leaflet/issues/161 
  outline: 1px solid transparent
  // work-around from here: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
  mix-blend-mode: plus-lighter

.leaflet-touch .leaflet-bar a
  width: 48px
  height: 48px
  line-height: 48px
